import { Component, EventEmitter, OnInit, Output, inject, ViewChild, ElementRef, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { ECharts, EChartsOption, PieSeriesOption } from "echarts";
import { map, takeUntil, tap } from "rxjs";
import { IQuickStartStep } from "src/app/core/interfaces";
import { AuthService } from "src/app/core/services/auth.service";
import {
  calculatePercentComplete,
  defaultQuickStartState,
  quickStartSteps,
  filteredQuickStartSteps,
} from "src/app/core/utils/quick-start-helpers";
import { UserStateModel } from "src/app/state/user-state/user-model.interface";
import { User } from "src/app/state/user-state/user.actions";
import { BaseComponent } from "../../../base/base.component";

interface QuickStartStepWithKey extends IQuickStartStep {
  key: string;
}

@Component({
  selector: "app-quick-start-draggable",
  templateUrl: "./quick-start-draggable.component.html",
  styleUrls: ["./quick-start-draggable.component.scss"],
})
export class QuickStartDraggableComponent
  extends BaseComponent
  implements OnInit
{
  @Output() closeQuickStart = new EventEmitter<void>();

  @ViewChild('quickStartContainer') quickStartContainer!: ElementRef;

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (this.quickStartContainer && !this.quickStartContainer.nativeElement.contains(target)) {
      this.minimizeQuickStart();
    }
  }

  private store = inject(Store);
  private router = inject(Router);
  private authService = inject(AuthService);

  user$ = this.store.select<UserStateModel>((state) => state.user);
  quickStartState$ = this.user$.pipe(
    map((user) => {
      if (user) {
        // return an ordered object of the quick start state using
        // the position property of each quick start step
        const qsState = user.quickStartState;
        if (!qsState) {
          return null;
        }
        const orderedQsState: QuickStartStepWithKey[] = [];
        const orderedKeys = Object.keys(qsState).sort(
          (a, b) => qsState[a].position - qsState[b].position
        );
        orderedKeys.forEach((key) => {
          orderedQsState.push({ ...qsState[key], key });
        });
        return orderedQsState.filter((qs) => {
          const stepPermission = quickStartSteps[qs.key]?.requiredPermission;
          if (!stepPermission || user.permissions.includes(stepPermission)) {
            return true;
          }
          return false;
        });
      } else {
        return null;
      }
    })
  );
  numberOfTasksRemaining = 0;
  quickStartSteps = filteredQuickStartSteps(this.authService);

  echartsInstance: ECharts | undefined;
  chartOption: EChartsOption = {
    series: [
      {
        type: "pie",
        radius: "100%",
        label: {
          show: false,
        },
        data: [
          { value: 0, name: "Completed", itemStyle: { color: "#00567a" } },
          {
            value: 100,
            name: "Remaining",
            itemStyle: { color: "#D3D3D3" },
          },
        ],
        emphasis: {
          disabled: true,
        },
      },
    ],
  };

  ngOnInit(): void {
    this.user$
      .pipe(
        takeUntil(this.isDestroyed$),
        tap((user) => {
          if (user) {
            const qsStatus = calculatePercentComplete(user);
            if (!qsStatus) {
              this.store.dispatch(
                new User.SetQuickStartState(defaultQuickStartState())
              );
              return;
            }
            this.setChartOption(qsStatus.percentComplete);
            this.numberOfTasksRemaining = qsStatus.tasksRemaining;
          }
        })
      )
      .subscribe();
  }

  /**
   * When the echart initializes in the template, this method is called.
   * It will set the echartsInstance property to the instance of the chart and
   * then get the percent complete from the user state and update the chart.
   */
  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;
    const userState = this.store.selectSnapshot<UserStateModel>(
      (state) => state.user
    );
    const qsStatus = calculatePercentComplete(userState);
    if (!qsStatus) {
      this.store.dispatch(
        new User.SetQuickStartState(defaultQuickStartState())
      );
      return;
    }
    this.setChartOption(qsStatus.percentComplete);
    this.numberOfTasksRemaining = qsStatus.tasksRemaining;
  }

  private setChartOption(percentComplete: number) {
    if (Array.isArray(this.chartOption.series)) {
      const chartData = this.chartOption.series[0].data as PieSeriesOption[];
      this.chartOption.series[0].data = chartData.map((d, i) => {
        if (i === 0) {
          return { ...d, value: percentComplete };
        } else {
          return { ...d, value: 100 - percentComplete };
        }
      });
    }
    this.echartsInstance?.setOption(this.chartOption);
  }

  minimizeQuickStart() {
    this.closeQuickStart.emit();
  }

  markAsDone(qsSection: string) {
    const userState = this.store.selectSnapshot<UserStateModel>(
      (state) => state.user
    );
    const qsState = userState.quickStartState;
    if (!qsState) {
      return;
    }
    const updatedQsState = {
      ...qsState,
      [qsSection]: {
        ...qsState[qsSection],
        finished: true,
      },
    };
    this.store.dispatch(new User.SetQuickStartState(updatedQsState));
  }

  checkCompletedState(qsSection: string) {
    if (qsSection === "multiFactor" && !this.authService.hasMultiFactor) {
      return true;
    }
    return false;
  }

  startStep(qsSection: string) {
    const actionUrl = this.quickStartSteps[qsSection].actionUrl;
    switch (qsSection) {
      case "groups":
        this.router.navigate([actionUrl]);
        break;
      case "users":
        this.router.navigate([actionUrl]);
        break;
      case "multiFactor":
        this.router.navigate([actionUrl]);
        break;
      case "entities":
        this.router.navigate([actionUrl]);
        break;

      default:
        break;
    }

    this.minimizeQuickStart();
  }
}
