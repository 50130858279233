import { Injectable, inject } from "@angular/core";
import {
  Firestore,
  doc,
  getDoc,
  setDoc,
  collection,
  where,
  query,
  getDocs,
  arrayRemove,
} from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { IEntityDetails } from "src/app/core/interfaces/entity.interface";
import { convertTimestampsToDates } from "src/app/core/utils/helper-functions";
import {
  EntityDetailsStateModel,
  generateBlankEntityDetails,
} from "./entity-details-model.interface";
import { EntityDetails } from "./entity-details.actions";
import { writeBatch } from "firebase/firestore";

@State<EntityDetailsStateModel>({
  name: "entityDetails",
  defaults: {
    ...generateBlankEntityDetails(),
  },
})
@Injectable()
export class EntityDetailsState {
  firestore = inject(Firestore);
  snackbar = inject(MatSnackBar);
  store = inject(Store);

  @Action(EntityDetails.Reset)
  reset(ctx: StateContext<EntityDetailsStateModel>) {
    ctx.setState({ ...generateBlankEntityDetails() });
  }

  @Action(EntityDetails.Get)
  async getEntityDetails(
    ctx: StateContext<EntityDetailsStateModel>,
    action: EntityDetails.Get
  ) {
    const { entityId } = action;

    const detailsDoc = doc(this.firestore, "entityDetails", entityId);
    const detailsSnapshot = await getDoc(detailsDoc);

    if (!detailsSnapshot.exists()) {
      return;
    }

    const details = convertTimestampsToDates(
      detailsSnapshot.data()
    ) as IEntityDetails;

    ctx.patchState({
      ...details,
      id: entityId,
    });
  }

  @Action(EntityDetails.Set)
  async setEntityDetails(
    ctx: StateContext<EntityDetailsStateModel>,
    action: EntityDetails.Set
  ) {
    const { entityId, entityDetails } = action;

    const detailsDoc = doc(this.firestore, "entityDetails", entityId);
    const detailsSnapshot = await getDoc(detailsDoc);

    if (detailsSnapshot.exists()) {
      // Update the existing entity details document
      const oldEntityDetails = detailsSnapshot.data() as IEntityDetails;

    if (entityDetails?.filings?.length < oldEntityDetails?.filings?.length) {
      console.error("Cannot remove filings from entity details");
      return;
    }

      await setDoc(detailsDoc, entityDetails, { merge: true });
    } else {
      // Create a new entity details document
      await setDoc(detailsDoc, {
        ...entityDetails,
        id: entityId,
      });
    }

    ctx.patchState({
      ...convertTimestampsToDates(entityDetails),
      id: entityId,
    });
  }

  @Action(EntityDetails.SetState)
  async setEntityDetailsState(
    ctx: StateContext<EntityDetailsStateModel>,
    action: EntityDetails.SetState
  ) {
    const { entityId, entityDetails } = action;

    ctx.patchState({
      ...convertTimestampsToDates(entityDetails),
      id: entityId,
    });
  }

  @Action(EntityDetails.UpdateOwnerAuditLog)
  async updateOwnerAuditLog(
    ctx: StateContext<EntityDetailsStateModel>,
    action: EntityDetails.UpdateOwnerAuditLog
  ) {
    const { ownerId, auditLog } = action;

    const batch = writeBatch(this.firestore);

    // select all EntityDetails that contains this ownerId
    const entityQuery = query(
      collection(this.firestore, "entityDetails"),
      where(`ownerIds`, "array-contains", ownerId)
    );

    const entitiesDetailSnapshot = await getDocs(entityQuery);

    // update the audit log for all entries that share ownerId
    entitiesDetailSnapshot.forEach((entityDetailsSnapshot) => {
      if (!entityDetailsSnapshot.exists()) {
        return;
      }

      const entityDetails = entityDetailsSnapshot.data() as IEntityDetails;
      const updatedEntityAuditLog = [auditLog, ...entityDetails.auditLog];

      const entityDetailsDoc = doc(
        this.firestore,
        "entityDetails",
        entityDetailsSnapshot.id
      );
      batch.update(entityDetailsDoc, {
        auditLog: updatedEntityAuditLog,
      });
    });

    await batch.commit();

    // if there is an active details data, update it with new audit log locally
    const entityDetails = ctx.getState();

    if (entityDetails.id) {
      entityDetails.auditLog.unshift(auditLog);

      ctx.dispatch(new EntityDetails.Set(entityDetails.id, entityDetails));
    }
  }

  @Action(EntityDetails.RemoveOwner)
  async removeAuditLogOwner(
    ctx: StateContext<EntityDetailsStateModel>,
    action: EntityDetails.RemoveOwner
  ) {
    const { entityId, ownerId } = action;
    const entityDetailsState = ctx.getState();

    if (entityDetailsState.id) {
      const updatedOwnerIds = entityDetailsState.ownerIds.filter(
        (detailsOwnerId) => detailsOwnerId !== ownerId
      );

      ctx.patchState({
        ownerIds: updatedOwnerIds,
      });

      return;
    }

    const batch = writeBatch(this.firestore);
    const entityDetailsDoc = doc(this.firestore, "entityDetails", entityId);

    batch.update(entityDetailsDoc, {
      ownerIds: arrayRemove(ownerId),
    });

    await batch.commit();
  }

  @Action(EntityDetails.UpdateAuditLog)
  async updateAuditLog(
    ctx: StateContext<EntityDetailsStateModel>,
    action: EntityDetails.UpdateAuditLog
  ) {
    const { entityId, auditLog } = action;

    // First, I'm going to try to get the entity
    // details from state. If it's not in state,
    // I'll get it from Firestore.
    let entityDetails = ctx.getState();
    if (!entityDetails.id) {
      const detailsDoc = doc(this.firestore, "entityDetails", entityId);
      const detailsSnapshot = await getDoc(detailsDoc);
      if (detailsSnapshot.exists()) {
        entityDetails = {
          ...(detailsSnapshot.data() as IEntityDetails),
          id: entityId,
        };
      }
    }
    // Now that we have the entity details, we can
    // update the audit log.
    entityDetails.auditLog.unshift(auditLog);
    // Finally, we'll save the updated entity details
    // to Firestore and state.
    ctx.dispatch(new EntityDetails.Set(entityId, entityDetails));
  }
}
