import { Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IBoirStatusResponse } from "src/app/core/interfaces";

@Component({
  selector: "app-status-dialog",
  templateUrl: "./status-dialog.component.html",
  styleUrls: ["./status-dialog.component.scss"],
})
export class StatusDialogComponent {
  public dialogRef: MatDialogRef<StatusDialogComponent> = inject(
    MatDialogRef<StatusDialogComponent>
  );
  public data: { status: IBoirStatusResponse } = inject(MAT_DIALOG_DATA);
}
