import { Component, inject } from "@angular/core";
import { Auth } from "@angular/fire/auth";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { UserStateModel } from "src/app/state/user-state/user-model.interface";
import { User } from "src/app/state/user-state/user.actions";

@Component({
  selector: "app-two-factor-dialog",
  templateUrl: "./two-factor-dialog.component.html",
  styleUrls: ["./two-factor-dialog.component.scss"],
})
export class TwoFactorDialogComponent {
  constructor(public dialogRef: MatDialogRef<TwoFactorDialogComponent>) {}
  private router: Router = inject(Router);
  private auth = inject(Auth);
  private store = inject(Store);

  onNoClick(): void {
    this.dialogRef.close();
  }

  decline2FA(): void {
    const loggedInUser = this.auth.currentUser?.uid;
    const user = this.store.selectSnapshot(
      (state) => state.user as UserStateModel
    );

    if (loggedInUser && user) {
      const updatedUser: UserStateModel = {
        ...user,
        skipTwoFactor: true,
        id: loggedInUser,
      };
      this.store.dispatch(new User.Update(updatedUser));
      this.store.dispatch(new User.Get(loggedInUser));
    }
    this.dialogRef.close();
  }

  navigate2FA(): void {
    this.router.navigate(["auth/two-factor-setup"]);
    this.dialogRef.close();
  }
}
