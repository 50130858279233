import { IEntity } from "src/app/core/interfaces";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { convertTimestampsToDates } from "src/app/core/utils/helper-functions";

export class SecureFileStateObject {
  async fetchEntityById(entityId: string): Promise<IEntity | undefined> {
    const db = getFirestore();
    const entityRef = doc(db, "secureFileEntities", entityId);
    const entitySnapshot = await getDoc(entityRef);
    const entity = entitySnapshot.data() as IEntity;
    return convertTimestampsToDates(entity);
  }
}
