import {
  IAuditLog,
  IEntityDetails,
} from "src/app/core/interfaces/entity.interface";

export namespace EntityDetails {
  export class Reset {
    static readonly type = "[EntityDetails] Reset";
  }

  export class Get {
    static readonly type = "[EntityDetails] Get";
    constructor(public entityId: string) {}
  }

  export class Set {
    static readonly type = "[EntityDetails] Set";
    constructor(
      public entityId: string,
      public entityDetails: IEntityDetails
    ) {}
  }

  export class SetState {
    static readonly type = "[EntityDetails] SetState";
    constructor(
      public entityId: string,
      public entityDetails: IEntityDetails
    ) {}
  }

  export class RemoveOwner {
    static readonly type = "[EntityDetails] Remove Owner";
    constructor(public entityId: string, public ownerId: string) {}
  }
  export class UpdateAuditLog {
    static readonly type = "[EntityDetails] Update Audit Log";
    constructor(public entityId: string, public auditLog: IAuditLog) {}
  }
  export class UpdateOwnerAuditLog {
    static readonly type = "[EntityDetails] Update Owner Audit Log";
    constructor(public ownerId: string, public auditLog: IAuditLog) {}
  }
}
