import { DocumentData } from "firebase-admin/firestore";
import { OrgCollectionName } from "./collection-model.interface";
import { IPublicOwner } from "src/app/core/interfaces";

export namespace Collection {
  export class Fetch {
    static readonly type = "[CollectionState] Fetch";
    constructor(
      public collectionName: OrgCollectionName,
      public orgId: string,
      public startAfterDoc: DocumentData | null = null
    ) {}
  }

  export class Set {
    static readonly type = "[CollectionState] Set";
    constructor(
      public collectionName: OrgCollectionName,
      public data: IPublicOwner[]
    ) {}
  }
}
