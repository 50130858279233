import { IEntity } from "src/app/core/interfaces";
import { SecureFileStateObject } from "./secure-file-model";

export interface SecureFileStateModel {
  entities: IEntity[];
  stateObject: SecureFileStateObject;
}

export const initialState: SecureFileStateModel = {
  entities: [],
  stateObject: new SecureFileStateObject(),
};
