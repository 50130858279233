import {
  IBoirFilingResponse,
  IEntity,
  IEntitySubmitConsent,
} from "./entity.interface";
import { UserType } from "./user.interface";

export interface INewsletterPost {
  title: string;
  description: string;
  postUrl: string;
  postImageUrl: string;
}

export interface ISendAutoCollectEmailJWTPayload {
  userType: UserType;
  ownerId: string;
  name: string;
  entityId?: string;
  groupId?: string;
  orgId?: string;
  legalName?: string;
  senderName: string;
  senderPhone?: string | null;
  senderEmail?: string | null;
  exp: number;
}

export interface IStripeLineItems {
  [key: string]: {
    line_items: { price: string; quantity: number; coupon?: string }[];
    discounts?: { coupon: string }[];
    mode: "payment" | "setup" | "subscription";
  };
}

export interface IBoirQueueItem extends IBoirFilingResponse {
  entity: IEntity;
  consentData: IEntitySubmitConsent;
  submittedSuccessfully: boolean;
  createdAt: Date;
  orgId: string | null;
}
