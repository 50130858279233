import { Injectable, inject } from "@angular/core";
import { Firestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { CollectionStateModel } from "./collection-model.interface";
import { Collection } from "./collection.actions";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { IPublicOwner } from "src/app/core/interfaces";

const generateBlankCollectionState = (): CollectionStateModel => {
  return {
    publicOwnerList: [],
  };
};

@State<CollectionStateModel>({
  name: "collectionState",
  defaults: {
    ...generateBlankCollectionState(),
  },
})
@Injectable()
export class CollectionState {
  firestore = inject(Firestore);
  snackbar = inject(MatSnackBar);
  store = inject(Store);

  @Action(Collection.Fetch)
  async fetchCollection(
    ctx: StateContext<CollectionStateModel>,
    action: Collection.Fetch
  ) {
    try {
      const limitSize = 1000;

      const { collectionName, startAfterDoc, orgId } = action;

      const collectionRef = collection(
        this.firestore,
        "organization",
        orgId,
        collectionName
      );

      let dataQuery = query(
        collectionRef,
        orderBy("lastModifiedDate", "desc"),
        limit(limitSize)
      );

      if (startAfterDoc) {
        dataQuery = query(dataQuery, startAfter(startAfterDoc));
      }

      const documentSnapshots = await getDocs(dataQuery);

      if (documentSnapshots.docs.length > 0) {
        const lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];

        ctx.setState({
          ...ctx.getState(),
          [collectionName]: [
            ...ctx.getState()[collectionName],
            ...documentSnapshots.docs.map((doc) => doc.data() as IPublicOwner),
          ],
        });

        ctx.dispatch(new Collection.Fetch(collectionName, orgId, lastVisible));
      } else {
        console.log("No more documents to load.");
      }
    } catch (error) {
      console.error("Error fetching documents: ", error);
    }
  }

  @Action(Collection.Set)
  async setCollection(
    ctx: StateContext<CollectionStateModel>,
    action: Collection.Set
  ) {
    const { collectionName, data } = action;

    ctx.setState({
      ...ctx.getState(),
      [collectionName]: data,
    });
  }
}
