import { Injectable, inject } from "@angular/core";
import { Auth } from "@angular/fire/auth";
import { multiFactor } from "firebase/auth";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private auth = inject(Auth);
  private storeInitializedSource = new BehaviorSubject<boolean>(false);
  public storeInitialized$: Observable<boolean> = this.storeInitializedSource.asObservable();

  setStoreInitialized(isInitialized: boolean): void {
    this.storeInitializedSource.next(isInitialized);
  }

  get multiFactor() {
    const userAuthObject = this.auth.currentUser;

    return userAuthObject ? multiFactor(userAuthObject) : undefined;
  }

  get enrolledFactors() {
    return this.multiFactor?.enrolledFactors;
  }

  get hasMultiFactor() {
    return this.enrolledFactors && this.enrolledFactors?.length > 0;
  }

  get emailVerified () {
    return this.auth.currentUser?.emailVerified;
  }

  get isPasswordUser() {
    return this.auth.currentUser?.providerData.find(
      (provider) => provider.providerId !== "password"
    );
  }
}
