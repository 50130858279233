<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <p *ngIf="data.message">{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    *ngIf="data.negativeActionButtonText"
    data-testid="action-negative-button"
    mat-button
    [mat-dialog-close]="false"
  >
    {{ data.negativeActionButtonText }}
  </button>
  <button
    *ngIf="data.positiveActionButtonText"
    data-testid="action-positive-button"
    mat-button
    [mat-dialog-close]="true"
    [color]="data.positiveAction ? 'primary' : 'warn'"
  >
    {{ data.positiveActionButtonText }}
  </button>
</mat-dialog-actions>
