<h2 mat-dialog-title>Failed to retrieve transcript</h2>
<mat-dialog-content>
  <p>
    If you are having trouble downloading the FinCEN transcript it may be the
    due to a change in FinCEN’s procedures regarding the accessibility of
    transcripts. An entity’s filing might be affected if the following two
    conditions <b>both</b> apply (1) the report was filed before October 3,
    2024; (2) the transcript was not downloaded by a user within
    <b>36 hours</b> of filing.
  </p>
  <p>
    If you are looking to find the BOIR ID or the status of the filing, you can
    find that information by clicking the symbol in the “Get Status” column.
  </p>
  <p>
    If you would like more information on this disruption or potential next
    steps, please check out this
    <a
      href="https://vortechsolutions.atlassian.net/servicedesk/customer/kb/view/154238980"
      target="_blank"
    >
      article
    </a>
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button [mat-dialog-close]="true" color="primary">
    OK
  </button>
</mat-dialog-actions>
